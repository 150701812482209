<template>
  <div class="orderBox">
    <NavBar title="我的订单"></NavBar>
    <van-sticky :offset-top="$pxToVw(92)">
      <div class="navBox">
        <div
          v-for="(item, index) of navList"
          :key="index"
          :class="{ active: navActive === index }"
          @click="switchNav(index)"
        >
          {{ item }}
        </div>
      </div>
    </van-sticky>
    <van-sticky :offset-top="$pxToVw(180)" v-show="listFilter.length > 0">
      <div class="contentBox bodyColor">
        <Space :height="30"></Space>
        <van-radio-group v-model="filter" direction="horizontal">
          <van-radio :name="item.type" v-for="(item, index) of listFilter" :key="index">
            {{ item.title }}
            <template #icon="props">
              <img class="selectIcon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-radio>
        </van-radio-group>
        <Space :height="23"></Space>
      </div>
    </van-sticky>
    <div class="contentBox flexAuto flexColumn">
      <ListView :get-list="getList" empty="暂无订单" pull-refresh ref="listView">
        <template v-slot="{ data }">
          <template v-for="(item, index) of data">
            <div class="listBox" :key="index">
              <div class="titleBox">
                <div class="title">{{ item.title }}</div>
                <div class="intro">{{ item.intro }}</div>
              </div>
              <div class="bottomBox">
                <div class="textBox" v-for="(ct, ci) of item.content" :key="ci">
                  <div>{{ ct.title }}</div>
                  <div :class="{ state: ct.color }">{{ ct.value }}</div>
                </div>
              </div>
            </div>
            <Space :height="30" :key="`space${index}`"></Space>
          </template>
        </template>
      </ListView>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      navActive: 0,
      navList: ['算力订单', '锁仓订单', '增速订单'],
      activeIcon: require('@/assets/images/locked/radio2.png'),
      inactiveIcon: require('@/assets/images/locked/radio1.png'),
      filter: '',
      listFilter: [],
    };
  },
  created() {
    this.navActive = Number(this.$route.query.i) || 0;
  },
  mounted() {
    this.$store.commit('setBodyColor');
  },
  methods: {
    async getList(page) {
      let url = '';
      if (this.navActive === 0) {
        // 算力订单记录
        url = '/v1/order/userOrderRecord';
      }
      if (this.navActive === 1) {
        // 锁仓记录
        url = '/v1/order/userLockedRecord';
      }
      if (this.navActive === 2) {
        // 增速订单
        url = '/v1/order/accelerateOrderRecord';
      }
      let res = await this.$http('get', url, {
        page: page,
        size: 5,
        status: this.filter,
      });

      if (this.navActive === 0) {
        let statusProcess = res => {
          switch (res) {
            case 1:
              return {
                value: '购买中',
                color: false,
              };
            case 2:
              return {
                value: '购买失败',
                color: false,
              };
            case 3:
              return {
                value: '收益中',
                color: true,
              };
            case 4:
              return {
                value: '已作废',
                color: false,
              };
            case 5:
              return {
                value: '已转移',
                color: false,
              };
          }
        };
        // 算力订单记录
        res.data.list = res.data.list.map(item => {
          return {
            title: item.goodsName,
            content: [
              {
                title: '订单时间',
                value: this.$dayjs(item.createTime).format('YYYY/MM/DD'),
              },
              {
                title: '订单T数',
                value: `${item.totalPower} TB`,
              },
              {
                title: '支付金额',
                value: `${item.realMoney}`,
              },
              {
                title: '订单状态',
                value: statusProcess(item.status).value,
                color: statusProcess(item.status).color,
              },
            ],
          };
        });
      }

      if (this.navActive === 1) {
        let statusProcess = res => {
          switch (res) {
            case 1:
              return {
                value: '收益中',
                color: true,
              };
            case 2:
              return {
                value: '已结束',
                color: false,
              };
          }
        };
        // 锁仓记录
        res.data.list = res.data.list.map(item => {
          return {
            title: item.activityTitle,
            intro: `预计年化${this.$accMul(item.apr, 100)}%`,
            content: [
              {
                title: '锁仓数量',
                value: `${item.lockedCoin} ${item.closeCoinName}`,
              },
              {
                title: '锁仓时间',
                value: this.$dayjs(item.createTime).format('YYYY/MM/DD'),
              },
              {
                title: '生效时间',
                value: this.$dayjs(item.comeTime).format('YYYY/MM/DD'),
              },
              {
                title: '赎回时间',
                value: this.$dayjs(item.completedTime).format('YYYY/MM/DD'),
              },
              {
                title: '订单状态',
                value: statusProcess(item.status).value,
                color: statusProcess(item.status).color,
              },
            ],
          };
        });
      }

      if (this.navActive === 2) {
        // 增速订单
        let statusProcess = res => {
          switch (res) {
            case 0:
              return {
                value: '增速中',
                color: true,
              };
            case 1:
              return {
                value: '已完成',
                color: false,
              };
          }
        };
        res.data.list = res.data.list.map(item => {
          return {
            title: '算力增速包',
            intro: '',
            content: [
              {
                title: '增速订单号',
                value: item.id,
              },
              {
                title: '增速量',
                value: `${item.acceleratePower} TiB`,
              },
              {
                title: '所需质押',
                value: `${item.filAmount} FIL`,
              },
              {
                title: '下单时间',
                value: this.$dayjs(item.createTime).format('YYYY/MM/DD'),
              },
              {
                title: '增速时间',
                value: this.$dayjs(item.startIncomeTime).format('YYYY/MM/DD'),
              },
              {
                title: '订单状态',
                value: statusProcess(item.status).value,
                color: statusProcess(item.status).color,
              },
            ],
          };
        });
      }

      return res;
    },
    switchNav(res) {
      this.navActive = res;
      this.filter = '';
    },
  },
  watch: {
    filter(res) {
      this.$refs.listView.reset();
    },
    navActive: {
      handler(rse) {
        if (this.navActive === 0) {
          // 算力订单记录
          this.listFilter = [
            {
              title: '全部',
              type: '',
            },
            {
              title: '收益中',
              type: '3',
            },
            {
              title: '已转移',
              type: '5',
            },
            {
              title: '已作废',
              type: '2',
            },
          ];
        }

        if (this.navActive === 1) {
          // 锁仓记录
          this.listFilter = [
            {
              title: '全部',
              type: '',
            },
            {
              title: '收益中',
              type: '1',
            },
            {
              title: '已结束',
              type: '2',
            },
          ];
        }

        if (this.navActive === 2) {
          // 增速订单
          this.listFilter = [];
        }
        this.$nextTick(() => {
          this.$refs.listView.reset();
        });
      },
      immediate: true,
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.bodyColor {
  background: #f3f3f8;
}
.navBox {
  display: flex;
  height: 90px;
  background: @white;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @intro-color;
    font-size: 30px;
    &.active {
      color: @button-black-color;
      font-weight: bold;
    }
  }
}
.selectIcon {
  width: 26px;
  height: 26px;
}
/deep/ .van-radio-group--horizontal {
  flex-wrap: nowrap;
  overflow-x: auto;
}
/deep/ .van-radio--horizontal {
  flex-shrink: 0;
  margin-right: 50px;
  &.van-radio {
    margin-bottom: 10px;
  }
}
.listBox {
  .radius;
  background: @white;
  .titleBox {
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @line-color1;
    padding: 0 26px;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: @text-color1;
    }
    .intro {
      font-size: 24px;
      color: @text-color3;
    }
  }
  .bottomBox {
    padding: 0 32px 26px;
    .textBox {
      padding-top: 22px;
      display: flex;
      justify-content: space-between;
      font-size: 26px;
      color: @text-color1;
      .state {
        color: @button-black-color;
      }
    }
  }
}
</style>
